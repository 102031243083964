import { createContext } from 'react';

const FenceContext = createContext<any>(null);

type FenceProviderProps = {
	children: React.ReactNode;
};

export const FenceProvider = ({ children }: FenceProviderProps) => {
	const fence = {
		id: 4,
	};

	const contextValues = {
		fence,
	};

	return (
		<FenceContext.Provider value={contextValues}>
			{children}
		</FenceContext.Provider>
	);
};

export default FenceContext;