import { Provider } from 'react-redux';

import store from '../redux/store';
import { FenceProvider } from './FenceContext';

type GlobalProviderProps = {
	children: React.ReactNode;
};

export function GlobalProvider({ children }: GlobalProviderProps) {
	return (
		<Provider store={store}>
			<FenceProvider>
				{children}
			</FenceProvider>
		</Provider>
	);
}