export default class StorageService {

	private readonly isWindow = typeof window !== 'undefined';

	getItem<T>(key: string): T | null {
		if (!this.isWindow) return null;
		const item = window.localStorage.getItem(key);
		return item ? JSON.parse(item) : null;
	}

	setItem(key: string, value: any): void {
		if (!this.isWindow) return;
		window.localStorage.setItem(key, JSON.stringify(value));
	}

	removeItem(key: string): void {
		if (!this.isWindow) return;
		window.localStorage.removeItem(key);
	}

	clear(): void {
		if (!this.isWindow) return;
		window.localStorage.clear();
	}
}