import { lazy } from 'react';

import { GlobalProvider } from '../context/GlobalProvider';
import AppRoutes from './AppRoutes';

const PageLayout = lazy(() => import('../components/Core/PageLayout'));

const App = () => {
  return (
    <GlobalProvider>
      <PageLayout>
        <AppRoutes />
      </PageLayout>
    </GlobalProvider>
  );
};

export default App;