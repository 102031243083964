import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

import { appRoutes } from '../lib/routes';

const Home = lazy(() => import('../pages/Home/Home'));
const Configurator = lazy(() => import('../pages/Configurator/Configurator'));
const NotFound = lazy(() => import('../components/NotFound/NotFound'));

const AppRoutes = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path={appRoutes.default} element={<Home />} />
        <Route path={appRoutes.configurator} element={<Configurator />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;