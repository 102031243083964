import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../../utils/axios';
import { FENCE_API } from '../../lib/apiroutes';
import { Fence, CreateFenceRequest, FenceResponse, UpdateFenceRequest } from '../../types/fence';

export const fenceApi = createApi({
	reducerPath: 'fence',
	baseQuery: axiosBaseQuery(),
	endpoints: (builder) => ({
		getFence: builder.query({
			query: (params?: any) => ({
				url: `${FENCE_API.GET_FENCE}`,
				method: 'GET',
			}),
			transformResponse: (response: { data: any }, meta: any, arg: any) => response,
		}),
		createFence: builder.mutation<Fence, CreateFenceRequest>({
			query: (body) => ({
				url: `${FENCE_API.CREATE_FENCE}/posts`,
				method: 'POST',
				data: body
			}),
			transformResponse: (response: any) => {
				// if (!response.data) {
				//     throw new Error('No data received from server');
				// }
				// return Array.isArray(response.data) ? response.data : [response.data];
				// Return the created fence object
				return response;
			},
		}),
		updateFence: builder.mutation<Fence, UpdateFenceRequest>({
			query: ({ id, ...body }) => ({
				url: `${FENCE_API.UPDATE_FENCE}/${id}`,
				method: 'PUT',
				data: body
			}),
			transformResponse: (response: FenceResponse) => {
				// Return the updated fence object
				return response.data as Fence;
			},
		}),
		deleteFence: builder.mutation<boolean, string>({
			query: (id) => ({
				url: `${FENCE_API.DELETE_FENCE}/${id}`,
				method: 'DELETE'
			}),
			transformResponse: (response: FenceResponse) => {
				// Return true if deletion was successful
				return response.status === 200;
			},
		}),
	}),
});

export const {
	useGetFenceQuery,
	useCreateFenceMutation,
	useUpdateFenceMutation,
	useDeleteFenceMutation
} = fenceApi;
